
























import { Component, Vue } from 'vue-property-decorator';
import { Getter } from 'vuex-class';

@Component({
    components: {
        PagePresetFullWidth: () => import('@/layouts/presets/FullWidth.vue'),
        CompanyOverview: () => import('@/components/company/CompanyOverview.vue'),
        Spinner: () => import('@/components/elements/Spinner.vue'),
    },
})
export default class PageCompanies extends Vue {
    @Getter('pageLoading') pageLoading!: boolean;

    i18n: string = 'views.supervisor';
}
